body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: #FF8C00;
  text-align: center;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
header {
  display: flex;
  font-size: xx-large;
  padding: 1%;
  justify-content: center;
}
.headerLink {
  padding: 0 1%;
}
footer{
  bottom: 0;
  right: 0;
  left: 0;
  padding-top: 10%;
}
a {
  text-decoration: none; 
  text-decoration: underline; 
  color: inherit; 
}
h2 {
  padding-top: 5%;
}
.morePadding{
  padding-bottom: 3% !important;
}
.personalInf {
  text-align: left;
  margin-left: 15%;
}
.course1 {
  text-align: left;
  padding: 5% 20% 0;
}
.course2 {
  text-align: right;
  padding-right: 20%;
}
.course3 {
  text-align: left;
  margin-left: 15%;
}
.course4 {
  text-align: left;
  margin-left: 55%;
}
.listRight {
  text-align: left;
  padding-left: 70%;
}
.listRight1 {
  text-align: left;
  padding-left: 78%;
}
.profileImg{
  border-radius: 20%;
  width: 20%;
  height: auto;  
  margin-left: 25%;
}
span {
  font-size: 70%;
}


/* pictues */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.sunReal {
  width: 340px;
  animation: pulse 4s ease-in-out alternate infinite;
}
.sun {
  animation: App-logo-spin infinite 20s linear;
  width: 300px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.planet {
  width: 300px;
  animation: bounce 4s infinite;
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0) translateX(0);
  }
  40% {
    transform: translateY(-10px) translateX(-10px);
  }
  60% {
    transform: translateY(-10px) translateX(10px);
  }
}
.tech{
  width: 200px;
}